/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Welcome to our knowledge base."), "\n", React.createElement(_components.p, null, "Our knowledge base includes articles that explain the features and functions of our API, as well as key concepts and terms that we use in our documentation."), "\n", React.createElement(_components.h2, null, "What our knowledge base contains"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/knowledge/glossary"
  }, "Glossary of terms."), " Our glossary of terms provides short definitions of key terms that we use throughout our API."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Articles that describe the features and functions of our API, and why we offer them to our merchants and partners. For example, what ", React.createElement(_components.a, {
    href: "/api/pagination"
  }, "Pagination"), " is and why we use it."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Articles that provide more detailed explanations of key concepts."), "\n"), "\n"), "\n", React.createElement(_components.p, null, "If you want help integrating with Payroc or using our API's key features, check out our ", React.createElement(_components.a, {
    href: "/guides/integrate"
  }, "Guides"), " and our ", React.createElement(_components.a, {
    href: "/api"
  }, "API Explorer"), "."), "\n", React.createElement(_components.p, null, "To test your integration, check out ", React.createElement(_components.a, {
    href: "/developer-resources/test-your-integration"
  }, "Test your integration.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
